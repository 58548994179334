import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTrigger,
    Dropdown,
    Option,
    Text,
    Divider,
    makeStyles,
} from "@fluentui/react-components";
import { FC, useEffect, useState } from "react";
import { Button } from "@fluentui/react-button";
import { usePersonas } from "../../../../libs/hooks/usePersonas";
import { IChatPersona } from "../../../../libs/models/ChatPersona";
import { useChat } from "../../../../libs/hooks/useChat";

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
    dropdown: {
        width: '100%',
    },
    paddingBottom: {
        paddingBottom: '10px',
    },
});

interface PersonaDialogProps {
    open: boolean;
    onCloseDialog: () => void;
}

export const PersonaChatDialog: FC<PersonaDialogProps> = ({open, onCloseDialog}) => {
    const chat = useChat();
    const classes = useClasses();
    const personasHook = usePersonas();
    const [personas, setPersonas] = useState<IChatPersona[]>([]);
    const [selectedPersonaId, setSelectedPersonaId] = useState<string | undefined>(undefined);

    useEffect(() => {
        personasHook.getPersonasByUser().then((result) => {
            setPersonas(result);
        }).catch((error) => {
            console.error("Failed to fetch personas:", error);
        });
    }, []);
    const createPersonaChat = () => {
        void chat.createChat(selectedPersonaId, personas.find(x => x.id == selectedPersonaId)?.name);
        onCloseDialog();
    };
    const closeDialog = () => {
        onCloseDialog();
        setTimeout(() => {
            setSelectedPersonaId(undefined);
        }, 200);
    };

    return (
        <div>
            <Dialog open={open}>
                <DialogSurface className={classes.root}>
                    <DialogBody>
                        <DialogContent>
                            <Dropdown className={classes.dropdown} placeholder={"Select a Persona"} onOptionSelect={(_, data) => {
                                setSelectedPersonaId(data.optionValue);
                            }}>
                                {personas.map((persona) => {
                                    return (
                                        <Option key={persona.id} value={persona.id} text={persona.name}>
                                            {persona.name}
                                        </Option>
                                    )
                                })}
                            </Dropdown>
                            <div className={classes.paddingBottom}>
                                <Divider appearance={"subtle"}/>
                            </div>
                            <Text>
                                {personas.find(x => x.id == selectedPersonaId)?.description}
                            </Text>
                        </DialogContent>
                        <DialogActions className={classes.actions}>
                            <DialogTrigger>
                                <Button appearance="secondary" onClick={closeDialog}>Cancel</Button>
                            </DialogTrigger>
                            <DialogTrigger>
                                <Button appearance="primary" onClick={createPersonaChat}>Create</Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    )
}
