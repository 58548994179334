import { Input, makeStyles, shorthands } from "@fluentui/react-components";
import { PromptCard } from "../prompt-library/PromptCard";
import { usePrompts } from "../../../libs/hooks/usePrompts";
import { useEffect, useState } from "react";
import { IChatPrompt } from "../../../libs/models/ChatPrompt";
import { SearchRegular } from "@fluentui/react-icons";

const useStyles = makeStyles({
    root: {
        ...shorthands.margin('24px'),
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: "auto",
    },
    main: {
        ...shorthands.gap("36px"),
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    search: {
        marginBottom: "24px",
        maxWidth: "300px",
    },
    section: {
        width: "fit-content",
    },
});

export const PromptsTab: React.FC = () => {
    const styles = useStyles();
    const promptsHook = usePrompts();
    const [prompts, setPrompts] = useState<IChatPrompt[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        promptsHook.getAllPrompts().then((result) => {
            setPrompts(result);
        }).catch((error) => {
            console.error("Failed to fetch personas:", error);
        });// eslint-disable-next-line
    }, []);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    }

    const filteredPrompts = prompts.filter(prompt =>
        prompt.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        prompt.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
        prompt.tags.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    return (
        <div className={styles.root}>
            <Input contentBefore={<SearchRegular/>} className={styles.search} onChange={handleSearchChange}/>
            <div className={styles.main}>
                {filteredPrompts.map((prompt, index) => (
                    <PromptCard
                        key={index}
                        id={prompt.id}
                        title={prompt.name}
                        description={prompt.description}
                        persona={prompt.personaId}
                        prompt={prompt.prompt}
                        tags={prompt.tags}
                    />
                ))}
            </div>
        </div>
    )
}
