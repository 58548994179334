import { Button, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { AppState } from '../../App';
import LitiumLogo from '../../assets/logo/LitiumLogo.png';
import { PluginGallery } from '../open-api-plugins/PluginGallery';
import { UserSettingsMenu } from './UserSettingsMenu';
import { SettingsRegular } from '@fluentui/react-icons';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';

export const useClasses = makeStyles({
    header: {
        alignItems: 'center',
        backgroundColor: tokens.colorBrandForeground2,
        color: tokens.colorNeutralForegroundOnBrand,
        display: 'flex',
        '& h1': {
            paddingLeft: tokens.spacingHorizontalXL,
            display: 'flex',
        },
        height: '64px',
        justifyContent: 'space-between',
        width: '100%',
    },
    cornerItems: {
        display: 'flex',
        ...shorthands.gap(tokens.spacingHorizontalS),
    },
    logo: {
        height: '64px',
        width: 'auto',
        cursor: 'pointer',
    },
});

interface HeaderProps {
    appState: AppState;
    setAppState: (state: AppState) => void;
}

export const LitiumHeader: React.FC<HeaderProps> = ({appState, setAppState}) => {
    const classes = useClasses();
    const {userAdminSettings} = useAppSelector((state: RootState) => state.app);

    const onAdminButtonClick = () => {
        setAppState(AppState.Admin);
    }

    const onLitiumLogoClick = () => {
        if (appState >= AppState.Chat) {
            setAppState(AppState.Chat);
        }
    }

    return (
        <div className={classes.header}>
            <img src={LitiumLogo} alt="Logo" className={classes.logo} onClick={onLitiumLogoClick}/>
            {appState > AppState.SettingUserInfo && (
                <div className={classes.cornerItems}>
                    <div className={classes.cornerItems}>
                        {(userAdminSettings.isPersonaAdmin || userAdminSettings.isPromptAdmin) && (
                            <Button
                                data-testid="adminButton"
                                style={{color: 'white'}}
                                appearance="transparent"
                                icon={<SettingsRegular color="white"/>}
                                title="Administration"
                                aria-label="Administration"
                                onClick={onAdminButtonClick}
                            >
                                Administration
                            </Button>
                        )}
                        <PluginGallery/>
                        <UserSettingsMenu
                            setLoadingState={() => {
                                setAppState(AppState.SigningOut);
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};
